<template>
  <div class="view-upload">
    <el-upload
      class="upload-demo"
      drag
      :action="action"
      :multiple="multiple"
      :file-list="fileList"
      :show-file-list="false"
      :data="uploadData"
      :on-success="onSuccess"
      :on-error="onError"
      :before-upload="beforeUpload"
    >
      <svg-icon iconClass="upload" className="icon" />
      <p class="el-upload__text">将文件拖到此处</p>
      <p class="el-upload__text">或</p>
      <div class="btn">选择文件</div>
    </el-upload>
    <div class="file-list">
      <div v-for="(item, $index) in fileList" :key="$index" class="panle">
        <div class="left">
          <svg-icon className="icon1" :iconClass="item.icon" />
          <div class="file">
            <p class="file-name">{{ item.name }}</p>
            <p class="file-size">{{ item.size }}</p>
          </div>
        </div>
        <div class="right">
          <i class="el-icon-circle-check gou" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/utils/config'
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    uploadData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    uploadType: {
        type: Number,
        default: 0 // 0:导入分区 1：导入价格 2: 其他
    },
    action: {
        type: String,
        default: `/backEnd/api/${config.supplyCode}/MakingSetting/importExpressAccountZone`
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  methods: {
    init() {
        this.fileList = []
    },
    onSuccess(res, file, fileList) {
      console.log(res, file, fileList)
      if (res.success) {
        const arr = fileList.map((item) => {
          return {
            name: item.name,
            size: `${item.size} B`,
            icon: item.name.split('.')[1]
          }
        })
        this.fileList = arr
        this.$emit('success', res.data)
      } else {
        this.fileList = []
        this.$message.error(res.errorMessage)
      }
    },
    onError(err) {
      this.$message.error(err)
    },
    beforeUpload(file) {
      if (this.uploadType === 0 && this.uploadData.type === '') {
        this.$message.error('请选择分区子类目类型后上传')
        return false
      } else {
        this.$emit('beforeUpload', file)
      }
      console.log(file)
    }
  }
}
</script>

<style lang="less" scoped>
.view-upload {
  width: 100%;
  height: auto;
  .upload-demo {
    width: 100%;
    height: 200px;
  }
  &:deep(.el-upload) {
    width: 100%;
    height: 200px;
  }
  &:deep(.el-upload-dragger) {
    width: 100%;
    height: 200px;
  }
  &:deep(.el-upload-dragger) {
    background: #f7f7f7;
  }
  &:deep(.el-upload__text) {
    width: 100%;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    margin-top: 10px;
  }
  .btn {
    width: 120px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #2395f3;
    margin: 10px auto 0;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 40px;
  }
  .file-list {
    width: 100%;
    height: auto;
    margin-top: 20px;
    .left {
      display: flex;
      align-items: center;
    }
    .panle {
      width: calc(100% - 44px);
      height: 64px;
      padding: 12px 20px 12px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
    }
    .file {
      margin-left: 15px;
      .file-name {
        height: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 19px;
      }
      .file-size {
        height: 16px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 16px;
        margin-top: 4px;
      }
    }
    .gou {
      font-size: 18px;
      color: #35ba83;
    }
  }
}
.icon {
  font-size: 40px;
  color: #2395f3;
  margin-top: 24px;
}
.icon1 {
  font-size: 40px;
  color: #2395f3;
}
</style>
