var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-upload" },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            drag: "",
            action: _vm.action,
            multiple: _vm.multiple,
            "file-list": _vm.fileList,
            "show-file-list": false,
            data: _vm.uploadData,
            "on-success": _vm.onSuccess,
            "on-error": _vm.onError,
            "before-upload": _vm.beforeUpload,
          },
        },
        [
          _c("svg-icon", { attrs: { iconClass: "upload", className: "icon" } }),
          _c("p", { staticClass: "el-upload__text" }, [
            _vm._v("将文件拖到此处"),
          ]),
          _c("p", { staticClass: "el-upload__text" }, [_vm._v("或")]),
          _c("div", { staticClass: "btn" }, [_vm._v("选择文件")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "file-list" },
        _vm._l(_vm.fileList, function (item, $index) {
          return _c("div", { key: $index, staticClass: "panle" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("svg-icon", {
                  attrs: { className: "icon1", iconClass: item.icon },
                }),
                _c("div", { staticClass: "file" }, [
                  _c("p", { staticClass: "file-name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c("p", { staticClass: "file-size" }, [
                    _vm._v(_vm._s(item.size)),
                  ]),
                ]),
              ],
              1
            ),
            _vm._m(0, true),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right" }, [
      _c("i", { staticClass: "el-icon-circle-check gou" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }